import { useCountryCode, useLocale } from 'src/app/shared/hooks/l10n';
import { useDLocalPermanentPaymentMethod } from 'src/app/shared/hooks/payments';

import { useMembershipModalState } from '../../modalContext';

import { useBraintreePermanentPaymentMethod } from './useBraintreePermanentPaymentMethod';
import type { PaymentType as PaypalPaymentType } from './useBraintreePermanentPaymentMethod';
import { useStripePermanentPaymentMethod } from './useStripePermanentPaymentMethod';

// eslint-disable-next-line max-lines-per-function
export function usePreparePaymentMethod(): (token?: string) => Promise<void> {
	const countryCode = useCountryCode();
	const locale = useLocale();

	const [
		{
			gateway,
			tokenizationData,
			email,
			usingSavedPaymentMethod,
			paymentType,
			currencyCode,
			savingPaypalTemporarilyEnabled,
		},
	] = useMembershipModalState();

	const prepareDLocalPermanentPaymentMethod = useDLocalPermanentPaymentMethod();

	const prepareStripePermanentPaymentMethod = useStripePermanentPaymentMethod();

	const prepareBraintreePermanentPaymentMethod = useBraintreePermanentPaymentMethod();

	return async (token?: string): Promise<void> => {
		if (gateway === 'DLOCAL') {
			if (!tokenizationData) return;
			await prepareDLocalPermanentPaymentMethod({
				...tokenizationData,
				shouldSavePaymentMethod: !usingSavedPaymentMethod,
				customer: {
					email,
				},
				usage: 'OFF_SESSION',
			});
		} else if (gateway === 'STRIPE') {
			await prepareStripePermanentPaymentMethod({
				...tokenizationData,
				paymentType,
				usage: 'OFF_SESSION',
				email,
				countryCode,
				currencyCode,
				locale,
				// new token is generated when payment type is apple pay or google pay
				// it should be used not the one from tokenizationData
				...(token ? { token, tokenType: 'temporary' } : {}),
			});
		} else if (gateway === 'BRAINTREE') {
			if (savingPaypalTemporarilyEnabled && tokenizationData?.tokenType !== 'permanent') {
				await prepareBraintreePermanentPaymentMethod({
					...tokenizationData,
					paymentType: paymentType as PaypalPaymentType,
					usage: 'OFF_SESSION',
					email,
				});
			}
		} else {
			throw new Error('Unsupported gateway');
		}
	};
}
