import { useUtilityContext } from '@change-corgi/core/react/utilityContext';
import type { PaymentMethodUsage } from '@change-corgi/middle-end-gql-schema';

import { saveBraintreePaymentMethod } from 'src/app/shared/api/payments';

export type PaymentType = 'PAYPAL' | 'VENMO';

export function useBraintreePermanentPaymentMethod(): (params: {
	paymentType: PaymentType;
	token?: string;
	tokenType?: 'temporary' | 'permanent';
	usage: PaymentMethodUsage;
	email: string;
}) => Promise<void> {
	const utilityContext = useUtilityContext();

	return async (params): Promise<void> => {
		const { token, usage, email } = params;
		const paymentMethod = { id: token };
		if (paymentMethod.id) {
			await saveBraintreePaymentMethod({
				token: paymentMethod.id,
				email,
				usage,
				utilityContext,
				paymentType: 'PAYPAL',
			});
		}
	};
}
