import { useI18n } from '@change-corgi/core/react/i18n';

import type { PaymentType } from 'src/app/shared/utils/payments';

type Result = ModelHookResult<
	{
		label?: string;
		a11yLabel: string;
		logoSrc?: string;
		width?: string;
		lineHeight?: number;
		backgroundColor?: string;
		logoSrcExp?: string;
		labelExp?: string;
	},
	{
		setUserSelectedPaymentType: () => void;
	}
>;

// TODO: Add fix for disabling the lint rule as a part of CHANGE-61066
// eslint-disable-next-line max-lines-per-function
export function usePaymentTypeButton({
	type,
	selectedPaymentType,
	onSelectNewPaymentMethod,
}: {
	type: PaymentType;
	selectedPaymentType: PaymentType;
	onSelectNewPaymentMethod: (selectedPaymentType: PaymentType) => void;
}): Result {
	const { translate } = useI18n();

	const selected = selectedPaymentType === type;

	return {
		data: {
			// TODO: consider moving this object to a sub-hook usePaymentTypes if it grows in size/complexity
			creditCard: {
				label: translate('fe.components.payments.types.credit_card'), // todo: move strings to corgi namespace
				a11yLabel: translate('fe.components.payments.types.credit_card'),
				logoSrc: 'https://static.change.org/payment-options/btn/credit-card-new.svg',
				width: '28px',
				lineHeight: 1,
				labelExp: '+ Add card',
				logoSrcExp: 'https://static.change.org/payment-options/btn/card_types.svg',
			},

			paypal: {
				a11yLabel: translate('fe.components.payments.types.paypal'),
				logoSrc: 'https://static.change.org/payment-options/btn/paypal-new.svg',
				width: '75px',
				backgroundColor: '#FFC439',
				labelExp: 'Pay with PayPal',
			},
			sepa: {
				label: translate('fe.components.payments.types.sepa'), // todo: move strings to corgi namespace
				a11yLabel: translate('fe.components.payments.types.sepa'),
				logoSrc: selected
					? 'https://static.change.org/payment-options/btn/sepa-active-dark.svg'
					: 'https://static.change.org/payment-options/btn/sepa-inactive-dark.svg',
				width: '25px',
				lineHeight: 1,
			},
			googlePay: {
				label: translate('fe.components.payments.types.google_pay'),
				a11yLabel: translate('fe.components.payments.types.google_pay'),
				logoSrc: selected
					? 'https://static.change.org/payment-options/btn/googlepay-active.png'
					: 'https://static.change.org/payment-options/btn/googlepay-inactive.png',
				width: '50px',
				backgroundColor: '#000000',
				logoSrcExp: 'https://static.change.org/payment-options/btn/gpay_transparent.svg',
				labelExp: 'Pay With Google Pay',
			},
			applePay: {
				a11yLabel: translate('fe.components.payments.types.apple_pay'),
				logoSrc: selected
					? 'https://static.change.org/payment-options/btn/applepay-active-dark.svg'
					: 'https://static.change.org/payment-options/btn/applepay-inactive-dark.svg',
				width: '50px',
				backgroundColor: '#000000',
				logoSrcExp: 'https://static.change.org/payment-options/btn/applepay-active-dark.svg',
				labelExp: 'Pay With Apple Pay',
			},
			venmo: {
				a11yLabel: translate('corgi.payments.types.venmo'),
				logoSrc: 'https://static.change.org/payment-options/btn/venmo-new.svg',
				width: '75px',
				backgroundColor: '#FFC439',
				labelExp: 'Pay with Venmo',
			},
			type,
		}[type],
		actions: {
			setUserSelectedPaymentType: () => {
				onSelectNewPaymentMethod(type);
			},
		},
	};
}
