import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { savePaymentMethod } from '../paymentMethod';

type PaymentType = 'PAYPAL' | 'VENMO';

type Params = {
	paymentType: PaymentType;
	token: string;
	email: string;
	usage: 'OFF_SESSION' | 'ON_SESSION';
	utilityContext: UtilityContext;
};

export async function saveBraintreePaymentMethod({
	token,
	email,
	usage,
	utilityContext,
	paymentType,
}: Params): Promise<void> {
	await savePaymentMethod({
		input: {
			customer: { email },
			gateway: 'BRAINTREE',
			usage,
			token,
			type: paymentType,
			gatewayData: null,
		},
		utilityContext,
	});
}
