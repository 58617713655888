import type { JSX } from 'react';

import { Box, Flex } from '@change-corgi/design-system/layout';

import { AmountSelector } from '../components/AmountSelector';
import { ContinueButton } from '../components/ContinueButton';
import { NewPaymentMethodPaypal } from '../components/NewPaymentMethodPaypal';
import { Selection } from '../components/Selection';
import { StripePaymentRequestButton } from '../components/StripePaymentRequestButton';
import { ValidationError } from '../components/ValidationError';
import type { ModalCallbackProps } from '../shared/types';

import { useMembershipForm } from './hooks/useMembershipForm';
import { useUserEventListeners } from './hooks/useUserEventListeners';

export function MembershipForm({ onAccept, onDecline }: ModalCallbackProps): JSX.Element {
	const {
		actions: { onSelectNo, onSelectYes, onSubmit },
		data: {
			acceptMembership,
			qaSuffix,
			copy: { yes, no },
		},
	} = useMembershipForm({ onAccept, onDecline });

	useUserEventListeners({ onAccept, onDecline });

	return (
		<form onSubmit={onSubmit} sx={{ mt: 24 }}>
			<Flex sx={{ flexDirection: 'column', gap: '16px' }}>
				<Selection
					qaSelectorSelection="ppmu-modal-selection-true"
					qaSelectorLabel={`ppmu-modal-selection-yes-title${qaSuffix}`}
					qaSelectorMessage={`ppmu-modal-selection-yes-description${qaSuffix}`}
					label={yes.label}
					message={yes.message}
					amount={yes.amount}
					highlight={yes.highlight}
					checked={acceptMembership}
					onSelect={onSelectYes}
				>
					<AmountSelector />
				</Selection>
				<Selection
					qaSelectorSelection="ppmu-modal-selection-false"
					qaSelectorLabel="ppmu-modal-selection-no-title"
					qaSelectorMessage={`ppmu-modal-selection-no-description${qaSuffix}`}
					label={no.label}
					message={no.message}
					checked={!acceptMembership}
					onSelect={onSelectNo}
				/>
				<ValidationError />
				<Box>
					<ContinueButton />
					<NewPaymentMethodPaypal onAccept={onAccept} onDecline={onDecline} />
					<StripePaymentRequestButton onAccept={onAccept} onDecline={onDecline} />
				</Box>
			</Flex>
		</form>
	);
}
