/* eslint-disable complexity */
import camelCase from 'lodash/fp/camelCase';
import flow from 'lodash/fp/flow';
import upperFirst from 'lodash/fp/upperFirst';

import { paymentMethod as getPaymentMethod } from './paymentMethod';
import { stripeErrorType } from './stripe/stripeErrorType';

import type { PaymentType } from './index';

type PaymentError = { type?: string; code?: string; message?: string };

export function transformPaymentErrorType({
	error,
	paymentType,
}: {
	error?: PaymentError | string;
	paymentType: PaymentType;
}): string | undefined {
	const paymentGateway = upperFirst(getPaymentMethod(paymentType)?.gateway?.toLowerCase()) ?? undefined;
	if (!error) return `${paymentGateway}Error`;
	let errType;
	if (typeof error === 'string') errType = error;
	else errType = error.type ?? error.code ?? error.message ?? 'Error';
	let transformedErrorType;
	switch (paymentType) {
		case 'creditCard': {
			transformedErrorType = stripeErrorType(errType);
			if (transformedErrorType === 'StripeCardError') transformedErrorType = 'card_error';
			break;
		}
		case 'paypal':
			transformedErrorType = `${paymentGateway}${flow(camelCase, upperFirst)(errType)}`;
			break;
		case 'applePay':
			transformedErrorType = stripeErrorType(errType);
			break;
		case 'googlePay':
			transformedErrorType = stripeErrorType(errType);
			break;
		case 'sepa':
			transformedErrorType = stripeErrorType(errType);
			break;
		default:
			transformedErrorType = errType;
	}
	return transformedErrorType;
}
