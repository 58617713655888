import { useI18n } from '@change-corgi/core/react/i18n';

import type { PaymentMethodSaveOptions, PaymentType } from 'src/app/shared/utils/payments';

type Result = {
	amount?: number;
	billingAgreementDescription?: string;
	currency: string;
	disabled: boolean;
	email: string;
	flow: paypal.FlowType;
	loading: boolean;
	paymentMethodSaveOptions: PaymentMethodSaveOptions;
	shouldEnableButton?: boolean;
};

type ValidationResult = {
	valid: boolean;
	errors: Record<string, string>;
};

export function useData({
	currencyCode,
	amount,
	loading,
	email,
	paymentMethodSaveOptions,
	useDonationLanguage,
	validationResult,
	savePaypalTemporarily,
}: {
	currencyCode: string;
	amount: number;
	loading: boolean;
	email: string;
	paymentMethodSaveOptions: PaymentMethodSaveOptions;
	useDonationLanguage: boolean;
	validationResult: (paymentType: PaymentType) => ValidationResult;
	savePaypalTemporarily?: boolean;
}): Result {
	const { translate } = useI18n();
	const flow =
		paymentMethodSaveOptions.shouldSavePaymentMethod || savePaypalTemporarily
			? ('vault' as paypal.FlowType.Vault)
			: ('checkout' as paypal.FlowType.Checkout);

	return {
		currency: currencyCode,
		disabled: loading,
		email,
		flow,
		loading,
		paymentMethodSaveOptions,
		...(flow === 'checkout' && { amount }),
		...(flow === 'vault' && {
			billingAgreementDescription: useDonationLanguage
				? translate('corgi.payments.paypal_billing_agreement_description_donation')
				: translate('corgi.payments.paypal_billing_agreement_description'),
		}),
		shouldEnableButton: validationResult('paypal').valid,
	};
}
