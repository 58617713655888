import type { ComponentProps } from 'react';
import React from 'react';

import { Loader } from '@change-corgi/design-system/components/progressiveDisclosure';
import { Box, Flex } from '@change-corgi/design-system/layout';

import type { PaypalState } from 'src/app/shared/hooks/payments';
import { usePaypal } from 'src/app/shared/hooks/payments';

import type { PaypalButtonSDK } from '../PaypalButtonSDK';

type Props = {
	style?: ComponentProps<typeof PaypalButtonSDK>['style'];
};

const ColorStyles = {
	// TODO: implement real styles for this color if needed
	black: {
		bg: 'black',
		borderColor: 'black',
	},
	blue: {
		bg: '#0070ba',
		borderColor: '#0070ba',
	},
	// TODO: implement real styles for this color if needed
	gold: {
		bg: 'black',
		borderColor: 'black',
	},
	// TODO: implement real styles for this color if needed
	silver: {
		bg: 'black',
		borderColor: 'black',
	},
	white: {
		bg: 'white',
		borderColor: 'black',
	},
};

export function PaypalButtonLoading({ style = { color: 'white' } }: Props): React.JSX.Element {
	const paypalState: PaypalState = usePaypal();
	return (
		<Box
			hidden={paypalState.status === 'error'}
			data-testid="paypal-button-loading"
			backgroundColor={ColorStyles[style.color || 'white'].bg}
			sx={{
				height: ['45px'],
				width: '100%',
				borderColor: [ColorStyles[style.color || 'white'].borderColor],
			}}
			mb={16}
			variant="bordered"
		>
			<Flex sx={{ height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
				<Loader size="xs" color="highlight" />
			</Flex>
		</Box>
	);
}
