import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { savePaymentMethod } from 'src/app/shared/api/payments';

import { gqlPaymentType } from '../../gqlPaymentType';
import type { PaymentType } from '../../types';

export async function saveStripePaymentMethod({
	token,
	email,
	usage,
	utilityContext,
	paymentType,
	accountId,
	locale,
	countryCode,
}: {
	token: string;
	email: string;
	usage: 'OFF_SESSION' | 'ON_SESSION';
	utilityContext: UtilityContext;
	paymentType: PaymentType;
	accountId?: string;
	locale?: string;
	countryCode?: string;
}): Promise<void> {
	await savePaymentMethod({
		input: {
			customer: { email, locale, countryCode },
			gateway: 'STRIPE',
			usage,
			token,
			type: gqlPaymentType(paymentType),
			gatewayData: accountId ? { accountId } : null,
		},
		utilityContext,
	});
}
