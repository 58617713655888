import { useMemo } from 'react';

import { useSession } from 'src/app/shared/hooks/session';
import { getUsState } from 'src/app/shared/utils/unitedStatesMapping';

import { useMembershipModalState } from '../../modalContext';

function mapStateCodeToName(stateCode: string): string | null {
	const result = getUsState(stateCode);
	if (result) return result;
	return null;
}

export function useLocation(): string | null {
	const [{ localizedCopyExperimentResult }] = useMembershipModalState();
	const session = useSession();

	return useMemo(() => {
		if (!localizedCopyExperimentResult) return '';
		if (['variant_1', 'variant_3'].includes(localizedCopyExperimentResult.variant)) {
			if (localizedCopyExperimentResult.city) return localizedCopyExperimentResult.city;
			if (session.user?.city) return session.user.city;
			return null;
		}

		if (localizedCopyExperimentResult.stateCode) return mapStateCodeToName(localizedCopyExperimentResult.stateCode);
		if (session.user?.stateCode) return mapStateCodeToName(session.user.stateCode);
		return null;
	}, [localizedCopyExperimentResult, session]);
}
