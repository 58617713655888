import { useEffect } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';

import { validateAmount } from 'src/app/shared/utils/payments';

import { useMembershipModalState } from '../../../modalContext';

export function useRealtimeValidation(): void {
	const [
		{ amount, minAmount, maxAmount, customAmountEnabled, currencyCode, acceptMembership },
		{ setValidationErrors },
	] = useMembershipModalState();
	const { translate, localizeCurrency } = useI18n();

	useEffect(() => {
		if (!customAmountEnabled) {
			return;
		}

		if (!acceptMembership) {
			setValidationErrors(null);
			return;
		}

		const result = validateAmount({
			amount,
			minimumAmount: minAmount,
			maximumAmount: maxAmount,
		});
		if (result) {
			setValidationErrors({
				amount: translate('corgi.co.ppmu.modal.validation.amount', {
					min: localizeCurrency(minAmount, currencyCode),
					max: localizeCurrency(maxAmount, currencyCode),
				}),
			});
		} else {
			setValidationErrors(null);
		}
	}, [
		amount,
		customAmountEnabled,
		maxAmount,
		minAmount,
		setValidationErrors,
		translate,
		localizeCurrency,
		currencyCode,
		acceptMembership,
	]);
}
