import isEmail from 'validator/lib/isEmail';

import type { TranslationKey } from '@change-corgi/core/i18n';
import { translationKey } from '@change-corgi/core/i18n';

type EmailValidationArgs = {
	email: string;
};

export function validateEmail({ email }: EmailValidationArgs): TranslationKey | null {
	if (isEmail(email)) return null;

	return translationKey('fe.errors.credit_card.email'); // TODO: move i18n key to corgi namespace
}
