import { useMemo } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';

import { useSessionAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import { useCopyExperiment } from '../../../../hooks/useCopyExperiment';
import { useMembershipModalState } from '../../../../modalContext';

type Result = {
	headline: string;
	description: string;
};

export function useHeader(): Result {
	const [{ useDonationLanguage }] = useMembershipModalState();
	const session = useSessionAsync();
	const { translate } = useI18n();

	const { headline, description } = useCopyExperiment();

	const getSuffix = () => {
		return useDonationLanguage ? '_donation' : '';
	};

	const { headlineSuffix, name } = useMemo(() => {
		if (isLoaded(session)) {
			return {
				headlineSuffix: session.value.loginState === 'GUEST' ? '_guest' : '',
				name: session.value.user?.shortDisplayName ?? '',
			};
		}
		return {
			headlineSuffix: '',
			name: '',
		};
	}, [session]);

	return {
		headline: headline ?? translate(`corgi.co.ppmu.modal.headline${headlineSuffix}`, { name }),
		description: description ?? translate(`corgi.co.ppmu.modal.description${getSuffix()}`),
	};
}
