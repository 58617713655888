import React from 'react';

import { CardElement } from '@stripe/react-stripe-js';
import type { StripeCardElementChangeEvent } from '@stripe/stripe-js';

type Props = {
	onChange?: (event: StripeCardElementChangeEvent) => unknown;
};

export function CardElementWrapper({ onChange }: Props): React.JSX.Element {
	return <CardElement options={{ hidePostalCode: true, disableLink: true, hideIcon: true }} onChange={onChange} />;
}
