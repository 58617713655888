import React from 'react';

import type { WithTokenParams } from 'src/app/shared/types';
import type { PaymentMethodSaveOptions, PaymentType } from 'src/app/shared/utils/payments';

import { StripeForm } from '../../../../stripe/containers/v2/StripeForm';

import { useNewPaymentMethodStripe } from './hooks/useNewPaymentMethodStripe';

export type NewPaymentMethodStripeProps = {
	email: string;
	name: string;
	paymentMethodSaveOptions: PaymentMethodSaveOptions;
	beforeToken: (paymentType: PaymentType) => Promise<boolean>;
	onTokenError: (paymentType: PaymentType, err: Error) => void;
	onTokenInvalid: (paymentType: PaymentType, err: Error) => void;
	withToken: (params: WithTokenParams) => Promise<void>;
	validate: (paymentType: PaymentType) => Promise<boolean>;
	prePaymentChecks: (paymentType: PaymentType) => Promise<boolean>;
	submitButton: React.JSX.Element;
	phoneNumberInput?: React.JSX.Element;
};

export function NewPaymentMethodStripe({
	submitButton,
	phoneNumberInput,
	...props
}: NewPaymentMethodStripeProps): React.JSX.Element {
	const { data, actions } = useNewPaymentMethodStripe(props);

	return (
		<StripeForm
			{...data}
			{...actions}
			data-testid="stripe-form"
			submitButton={submitButton}
			phoneNumberInput={phoneNumberInput}
		/>
	);
}
