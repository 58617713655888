import type { JSX, PropsWithChildren } from 'react';

import { Radio } from '@change-corgi/design-system/components/forms';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { HighlightText } from './HighlightText';
import { Label } from './Label';

type Props = PropsWithChildren<{
	amount?: string;
	highlight?: string;
	label: string;
	message: string;
	onSelect: () => void;
	checked: boolean;
	qaSelectorSelection: string;
	qaSelectorLabel: string;
	qaSelectorMessage: string;
}>;

export function Selection({
	qaSelectorSelection,
	qaSelectorLabel,
	qaSelectorMessage,
	label,
	message,
	amount,
	highlight,
	onSelect,
	checked,
	children,
}: Props): JSX.Element {
	return (
		<Box>
			{highlight && <HighlightText text={highlight} selected={checked} />}
			<Box
				variant="bordered"
				sx={{
					p: 10,
					...(highlight
						? { borderRadius: 'standard', borderTopLeftRadius: 0, borderTopRightRadius: 0 }
						: { borderRadius: 'standard' }),
					...(checked ? { borderWidth: 2 } : {}),
					borderColor: checked ? 'secondary-blue' : 'neutral-grey400',
				}}
				onClick={onSelect}
			>
				<Radio
					data-qa={qaSelectorSelection}
					data-testid="selection-radio"
					checked={checked}
					readOnly
					label={
						<Box>
							<Label qaSelectorLabel={qaSelectorLabel} amount={amount} label={label} />
							<Flex sx={{ paddingTop: '8px', gap: '8', flexDirection: 'column' }}>
								<Text data-qa={qaSelectorMessage} size="small">
									{message}
								</Text>
								{children}
							</Flex>
						</Box>
					}
				/>
			</Box>
		</Box>
	);
}
