import type { PaymentMethodResult, Stripe, StripeElements } from '@stripe/stripe-js';

export async function createStripePaymentMethod(
	stripe: Stripe,
	elements: StripeElements,
	name: string,
	email: string,
): Promise<PaymentMethodResult> {
	const cardNumberElement = elements.getElement('cardNumber');
	const cardElement = elements.getElement('card');
	if (cardNumberElement) {
		return stripe.createPaymentMethod({
			type: 'card',
			card: cardNumberElement,
			billing_details: {
				name,
				email,
			},
		});
	}
	if (cardElement) {
		return stripe.createPaymentMethod({
			type: 'card',
			card: cardElement,
			billing_details: {
				name,
				email,
			},
		});
	}

	throw new Error('No card element found');
}
