import type { JSX } from 'react';

import { Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { useMembershipModalState } from '../modalContext';

export function ValidationError(): JSX.Element | null {
	const [{ validationErrors, customAmountEnabled }] = useMembershipModalState();
	if (!customAmountEnabled || !validationErrors || !validationErrors.amount) return null;
	return (
		<Flex data-qa="invalid-amount-error" sx={{ alignItems: 'center' }}>
			<span sx={{ fontSize: '24px' }}>⚠️</span>
			<Text fontWeight="bold" sx={{ paddingLeft: '16px', fontSize: '14px' }}>
				{validationErrors.amount}
			</Text>
		</Flex>
	);
}
