import { paymentMethod } from './paymentMethod';

import type { PaymentType } from './index';

type ShowSavePaymentMethodCheckbox = { stripe: boolean | undefined; paypal: boolean | undefined };

export function shouldSavePaymentMethod(
	showSavePaymentCheckbox: ShowSavePaymentMethodCheckbox,
	selectedPaymentType: PaymentType,
): boolean {
	if (['applePay', 'googlePay'].includes(selectedPaymentType)) return false;

	if (selectedPaymentType === 'venmo') return true;

	type PaymentTypeProperty = keyof typeof showSavePaymentCheckbox;
	if (selectedPaymentType in showSavePaymentCheckbox) {
		return !!showSavePaymentCheckbox[selectedPaymentType as PaymentTypeProperty];
	}

	const { gateway } = paymentMethod(selectedPaymentType);
	return !!showSavePaymentCheckbox[gateway.toLowerCase() as PaymentTypeProperty];
}
