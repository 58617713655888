import { useCallback } from 'react';

import type { PaymentRequest, StripePaymentRequestButtonElementClickEvent } from '@stripe/stripe-js';

import type { PaymentType } from 'src/app/shared/utils/payments';

type Params = {
	beforeToken: (paymentType: PaymentType) => Promise<boolean>;
	paymentRequest: PaymentRequest | null;
	paymentType: PaymentType;
	validate?: (paymentType: PaymentType) => Promise<boolean>;
	prePaymentChecks?: (paymentType: PaymentType) => Promise<boolean>;
};

export function useOnClick({
	beforeToken,
	paymentRequest,
	paymentType,
	validate,
	prePaymentChecks,
}: Params): (e: StripePaymentRequestButtonElementClickEvent) => Promise<void> {
	return useCallback(
		// eslint-disable-next-line complexity
		async (e) => {
			e.preventDefault();
			if (!(await beforeToken(paymentType))) return;

			if (validate && !(await validate(paymentType))) return;

			if (paymentType === 'applePay') {
				paymentRequest?.show();
				if (prePaymentChecks && !(await prePaymentChecks(paymentType))) {
					paymentRequest?.abort();
				}
			} else {
				if (prePaymentChecks && !(await prePaymentChecks(paymentType))) {
					return;
				}
				paymentRequest?.show();
			}
		},
		[beforeToken, paymentRequest, paymentType, validate, prePaymentChecks],
	);
}
