import { useMemo } from 'react';

import { useSession } from 'src/app/shared/hooks/session';

import { useMembershipModalState } from '../../modalContext';

import { useLocation } from './useLocation';

type Variant = 'variant_1' | 'variant_2' | 'variant_3' | 'variant_4';

const HEADLINE_LONG = '{username}, can you help more people in {location} have an impact?';
const HEADLINE_SHORT = '{username}, can you help more people in {location}?';
const HEADLINE_GUEST_LONG = 'Can you help more people in {location} have an impact?';
const HEADLINE_GUEST_SHORT = 'Can you help more people in {location}?';
const DESCRIPTION_CITY =
	'Your generosity will provide powerful tools and expert support to those fighting for change in {location} and the surrounding communities.';
const DESCRIPTION_STATE =
	'Your generosity will provide trusted, powerful tools and expert support to those fighting for change in {location} and across the country.';
const YES = 'Yes, I’ll contribute monthly to support Change.org in {location} and other communities';

const COPY: Record<
	Variant,
	{
		headline: string;
		headlineGuest: string;
		description: string;
		descriptionGeneric: string;
		yes: string;
		yesGeneric: string;
	}
> = {
	variant_1: {
		headline: HEADLINE_LONG,
		headlineGuest: HEADLINE_GUEST_LONG,
		description: DESCRIPTION_CITY,
		descriptionGeneric:
			'Your generosity will provide powerful tools and expert support to those fighting for change in your community and beyond.',
		yes: YES,
		yesGeneric: 'Yes, I’ll contribute monthly to support Change.org in your community',
	},
	variant_2: {
		headline: HEADLINE_LONG,
		headlineGuest: HEADLINE_GUEST_LONG,
		description: DESCRIPTION_STATE,
		descriptionGeneric:
			'Your generosity will provide trusted, powerful tools and expert support to those fighting for change in your state and across the country.',
		yes: YES,
		yesGeneric: 'Yes, I’ll contribute monthly to support Change.org in your state and other communities',
	},
	variant_3: {
		headline: HEADLINE_SHORT,
		headlineGuest: HEADLINE_GUEST_SHORT,
		description: DESCRIPTION_CITY,
		descriptionGeneric:
			'Your generosity will provide powerful tools and expert support to those fighting for change in your community and beyond.',
		yes: YES,
		yesGeneric: 'Yes, I’ll contribute monthly to support Change.org in your city and other communities',
	},
	variant_4: {
		headline: HEADLINE_SHORT,
		headlineGuest: HEADLINE_GUEST_SHORT,
		description: DESCRIPTION_STATE,
		descriptionGeneric:
			'Your generosity will provide trusted, powerful tools and expert support to those fighting for change in your state and across the country.',
		yes: YES,
		yesGeneric: 'Yes, I’ll contribute monthly to support Change.org in your state and other communities',
	},
};

type Result = {
	headline: string | null;
	description: string | null;
	yes: string | null;
};

const DEFAULT_RESULT: Result = {
	headline: null,
	description: null,
	yes: null,
};

function isVariant(value: string): value is Variant {
	return Object.keys(COPY).includes(value);
}

export function useCopyExperiment(): Result {
	const [{ localizedCopyExperimentResult }] = useMembershipModalState();
	const session = useSession();

	const location = useLocation();

	return useMemo(() => {
		if (!localizedCopyExperimentResult || localizedCopyExperimentResult.variant === 'control') {
			return DEFAULT_RESULT;
		}
		if (!isVariant(localizedCopyExperimentResult.variant)) {
			return DEFAULT_RESULT;
		}

		const { headline, headlineGuest, description, descriptionGeneric, yes, yesGeneric } =
			COPY[localizedCopyExperimentResult.variant];

		const headlineToUse = session.loginState === 'GUEST' || !session.user.shortDisplayName ? headlineGuest : headline;

		const username = session.user?.shortDisplayName ?? '';

		if (location === null) {
			const locationReplacement = ['variant_1', 'variant_3'].includes(localizedCopyExperimentResult.variant)
				? 'your community'
				: 'your state';
			return {
				headline: headlineToUse.replace('{username}', username).replace('{location}', locationReplacement),
				description: descriptionGeneric,
				yes: yesGeneric,
			};
		}
		return {
			headline: headlineToUse.replace('{username}', username).replace('{location}', location),
			description: description.replace('{location}', location),
			yes: yes.replace('{location}', location),
		};
	}, [localizedCopyExperimentResult, session, location]);
}
