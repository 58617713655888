import { cloneElement } from 'react';

import { FormError } from '@change-corgi/design-system/components/forms';
import { Flex } from '@change-corgi/design-system/layout';

import { useStripeForm } from '../v2/hooks/useStripeForm';

import { SingleForm } from './components/SingleForm';
import { SplitForm } from './components/SplitForm';
import type { StripeCreditCardFormPropsWithElements } from './types';

export function StripeCreditCardLayout({
	submitButton,
	children,
	...rest
}: StripeCreditCardFormPropsWithElements): JSX.Element {
	const {
		data: { errorMessage, validationErrors },
		actions: { handleSubmit, validateStripeElementOnChange, setValidationErrors },
	} = useStripeForm(rest);

	return (
		<form onSubmit={handleSubmit}>
			<Flex sx={{ flexDirection: 'column', gap: 16 }}>
				<Flex sx={{ flexDirection: 'column', gap: 12 }}>
					{children}
					{rest.layout === 'single' ? (
						<SingleForm
							{...rest}
							validationErrors={validationErrors}
							validateStripeElementOnChange={validateStripeElementOnChange}
							setValidationErrors={setValidationErrors}
						/>
					) : (
						<SplitForm
							{...rest}
							validationErrors={validationErrors}
							validateStripeElementOnChange={validateStripeElementOnChange}
						/>
					)}
				</Flex>
				<Flex sx={{ justifyContent: 'center' }}>
					{cloneElement(submitButton, {
						disabled: submitButton.props.disabled,
						loading: submitButton.props.loading,
					})}
				</Flex>
				{errorMessage && <FormError data-qa="stripe-form-error-message">{errorMessage}</FormError>}
			</Flex>
		</form>
	);
}
