import React from 'react';

import { Radio } from '@change-corgi/design-system/components/forms';
import { Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import type { PaymentType } from 'src/app/shared/utils/payments';

// eslint-disable-next-line complexity
export function WalletPaymentTypeButton({
	type,
	selectedPaymentType,
	a11yLabel,
	logoSrc,
	label,
	setUserSelectedPaymentType,
	usingSavedPaymentMethod,
	disabled = false,
	variant,
	availableNewPaymentMethods,
	width,
}: {
	type: PaymentType;
	selectedPaymentType: PaymentType;
	a11yLabel: string;
	logoSrc?: string;
	label?: string | undefined;
	setUserSelectedPaymentType: () => void;
	usingSavedPaymentMethod?: boolean;
	disabled: boolean;
	variant?: string;
	availableNewPaymentMethods?: PaymentType[];
	width?: string;
}): React.JSX.Element {
	return (
		<Flex
			sx={{
				fontWeight: 'semi-bold',
				borderWidth: selectedPaymentType === type && !usingSavedPaymentMethod ? '2px' : '1px',
				// Blue tint color isn't available in th new design system, so using rbga to calculate the background tint.
				backgroundColor:
					selectedPaymentType === type && !usingSavedPaymentMethod ? 'rgba(21, 125, 185, 0.08)' : undefined,
				borderColor: selectedPaymentType === type && !usingSavedPaymentMethod ? 'accent' : 'neutral-grey600',
				borderRadius: 'standard',
				width: '100%',
				height: ['60px', '60px'],
				justifyContent: 'center',
				flexDirection: 'column',
			}}
			variant="bordered"
			onClick={setUserSelectedPaymentType}
			data-testid={`payment-option-button-${type}`}
		>
			<Flex>
				<Flex ml={16} sx={{ width: '100%', justifyContent: 'left', alignItems: 'normal' }}>
					<Radio
						size="big"
						disabled={disabled}
						data-testid={`payment-option-radio-button-${type}`}
						label={
							<Flex ml={16} sx={{ width: '100%' }}>
								<Flex sx={{ flex: '1' }}>
									<Flex>
										<Text
											as="span"
											size={['small', 'small', 'default']}
											fontWeight="semibold"
											sx={{ textTransform: 'capitalize' }}
											aria-label={a11yLabel}
										>
											{variant !== 'variant_2' || (availableNewPaymentMethods && availableNewPaymentMethods?.length < 3)
												? label
												: ''}
										</Text>
									</Flex>
								</Flex>
							</Flex>
						}
						checked={selectedPaymentType === type && !usingSavedPaymentMethod}
						readOnly
					/>
				</Flex>

				<Flex mr={16} sx={{ justifyContent: 'right', alignItems: 'normal' }}>
					<img alt="" src={logoSrc} width={type === 'creditCard' ? '' : width} />
				</Flex>
			</Flex>
		</Flex>
	);
}
