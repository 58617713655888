import type { StripeInputNames } from './types/StripeInputNames';

export function stripeInputName(inputName?: string): StripeInputNames | undefined {
	if (!inputName) return undefined;

	if (inputName.toLowerCase().includes('number')) {
		return 'card_number';
	}
	if (inputName.toLowerCase().includes('expiry') || inputName.toLowerCase().includes('expired')) {
		return 'card_expiration';
	}
	if (inputName.toLowerCase().includes('cvc')) {
		return 'card_cvc';
	}
	if (inputName.toLowerCase().includes('iban')) {
		return 'account_number';
	}

	if (inputName === 'card') {
		return 'card';
	}

	return undefined;
}
