import type { PaymentGateway, PaymentType } from '@change-corgi/middle-end-gql-schema';

import type { PaymentMethod } from './types';

import type { PaymentType as TrackingPaymentType } from './index';

const GATEWAY_MAP: Record<TrackingPaymentType, PaymentGateway> = {
	creditCard: 'STRIPE',
	paypal: 'BRAINTREE',
	sepa: 'STRIPE',
	applePay: 'STRIPE',
	googlePay: 'STRIPE',
	venmo: 'BRAINTREE',
};

const TYPE_MAP: Record<TrackingPaymentType, PaymentType> = {
	creditCard: 'CREDIT_CARD',
	paypal: 'PAYPAL',
	sepa: 'SEPA_DEBIT',
	applePay: 'APPLE_PAY',
	googlePay: 'GOOGLE_PAY',
	venmo: 'VENMO',
};

export function paymentMethod(paymentType: TrackingPaymentType, token?: string): PaymentMethod {
	return {
		gateway: GATEWAY_MAP[paymentType],
		type: TYPE_MAP[paymentType],
		token,
	};
}
