import { useMemo } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';

import { useCopyExperiment } from 'src/app/shared/components/membershipAsk/hooks/useCopyExperiment';
import { useMembershipModalState } from 'src/app/shared/components/membershipAsk/modalContext';

type Result = {
	yes: {
		label: string;
		message: string;
		amount?: string;
		highlight: string;
	};
	no: {
		label: string;
		message: string;
	};
};

export function useCopy(): Result {
	const [{ amount, currencyCode, useDonationLanguage, customAmountEnabled }] = useMembershipModalState();
	const { translate, localizeDate, localizeCurrency } = useI18n();
	const { yes } = useCopyExperiment();

	const suffix = useDonationLanguage ? '_donation' : '';

	const startDate = useMemo(
		() => localizeDate(new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), 'date', 'long'),
		[localizeDate],
	);
	const { yesLabel, yesMessage } = useMemo(() => {
		if (useDonationLanguage || !customAmountEnabled) {
			return {
				yesLabel: yes || translate(`corgi.co.ppmu.modal.yes_title${suffix}`),
				yesMessage: translate(`corgi.co.ppmu.modal.yes_description${suffix}`, { startDate }),
			};
		}
		return {
			yesLabel: yes || translate('corgi.co.ppmu.modal.selection_yes_title'),
			yesMessage: translate('corgi.co.ppmu.modal.selection_yes_message', { startDate }),
		};
	}, [startDate, useDonationLanguage, customAmountEnabled, translate, suffix, yes]);

	return {
		yes: {
			label: yesLabel,
			message: yesMessage,
			amount: customAmountEnabled
				? undefined
				: translate('corgi.co.ppmu.modal.contribution_text', { amount: localizeCurrency(amount, currencyCode) }),
			highlight: translate('corgi.co.ppmu.modal.highlight_text'),
		},
		no: {
			label: translate('corgi.co.ppmu.modal.no_title'),
			message: translate(`corgi.co.ppmu.modal.no_description${suffix}`),
		},
	};
}
