import type { UtilityContext } from '@change-corgi/core/react/utilityContext';
import type { PaymentMethodUsage } from '@change-corgi/middle-end-gql-schema';

import { savePaymentMethod } from 'src/app/shared/api/payments';

// eslint-disable-next-line max-params
export async function saveDLocalPaymentMethod(
	token: string,
	usage: PaymentMethodUsage,
	accountId: string,
	email: string,
	utilityContext: UtilityContext,
): Promise<void> {
	await savePaymentMethod({
		input: {
			customer: { email },
			gateway: 'DLOCAL',
			usage,
			token,
			type: 'CREDIT_CARD',
			gatewayData: { accountId },
		},
		utilityContext,
	});
}
