import type { PaymentType } from 'src/app/shared/utils/payments';

type Props = {
	beforeToken: (paymentType: PaymentType) => Promise<boolean>;
	validate?: (paymentType: PaymentType) => Promise<boolean>;
};
export async function onClick({ beforeToken, validate }: Props): Promise<void> {
	if (!(await beforeToken('paypal'))) return;
	if (validate) {
		await validate('paypal');
	}
}
