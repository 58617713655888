import type { ChangeEventHandler } from 'react';
import React from 'react';

import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';

import { useI18n } from '@change-corgi/core/react/i18n';
import { Input } from '@change-corgi/design-system/components/forms';
import { Box, Flex } from '@change-corgi/design-system/layout';

import type { StripeCreditCardFormWithInternalProps } from '../../types';

function upperCaseFirstChar(s: string) {
	return s.charAt(0).toUpperCase() + s.substring(1).toLowerCase();
}

export function SplitForm(props: StripeCreditCardFormWithInternalProps): React.JSX.Element {
	const { translate } = useI18n();

	const {
		onCardNumberFieldFocus,
		onCardExpiryFieldFocus,
		onCardCVCFieldFocus,
		validationErrors,
		validateStripeElementOnChange: onChange,
	} = props;

	return (
		<Flex sx={{ gap: 12, flexDirection: 'column' }}>
			<Box
				data-testid="credit-card-number"
				sx={{
					position: 'relative',
					WebkitTransform: 'translate3d(0, 0, 0)',
				}}
			>
				<Input
					className="iframe-form-element" // TODO: this is here for the regression-qaa tests. Migrate the tests to use the data-qa attribute.
					data-qa="iframe-form-element"
					error={validationErrors?.card_number ? translate(validationErrors.card_number) : null}
					helperText={translate('corgi.payments.stripe_elements.card_number')}
					name="card-number"
					as={CardNumberElement}
					onFocus={onCardNumberFieldFocus}
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					onChange={onChange as unknown as ChangeEventHandler<HTMLInputElement>}
					id="card-number-input"
					sx={{
						height: 40,
						display: 'flex',
						alignItems: 'center',
						// eslint-disable-next-line @typescript-eslint/naming-convention
						'.StripeElement': {
							width: '100%',
						},
					}}
				/>
			</Box>

			<Flex sx={{ width: '100%', gap: 12 }}>
				<Box sx={{ flex: 1 }} data-testid="credit-card-expiry">
					<Input
						className="iframe-form-element" // TODO: this is here for the regression-qaa tests. Migrate the tests to use the data-qa attribute.
						data-qa="iframe-form-element"
						error={validationErrors?.card_expiration ? translate(validationErrors.card_expiration) : null}
						helperText={upperCaseFirstChar(translate('corgi.co.promote.expiration_label'))}
						name="card-expiration"
						as={CardExpiryElement}
						onFocus={onCardExpiryFieldFocus}
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						onChange={onChange as unknown as ChangeEventHandler<HTMLInputElement>}
						id="card-expiry-input"
						sx={{
							height: 40,
							display: 'flex',
							alignItems: 'center',
							// eslint-disable-next-line @typescript-eslint/naming-convention
							'.StripeElement': {
								width: '100%',
							},
						}}
					/>
				</Box>
				<Box sx={{ flex: 1 }} data-testid="credit-card-cvc">
					<Input
						className="iframe-form-element" // TODO: this is here for the regression-qaa tests. Migrate the tests to use the data-qa attribute.
						data-qa="iframe-form-element"
						error={validationErrors?.card_cvc ? translate(validationErrors.card_cvc) : null}
						helperText={translate('corgi.payments.stripe_elements.cvc')}
						name="card-cvc"
						as={CardCvcElement}
						onFocus={onCardCVCFieldFocus}
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						onChange={onChange as unknown as ChangeEventHandler<HTMLInputElement>}
						id="card-cvc-input"
						sx={{
							height: 40,
							display: 'flex',
							alignItems: 'center',
							// eslint-disable-next-line @typescript-eslint/naming-convention
							'.StripeElement': {
								width: '100%',
							},
						}}
					/>
				</Box>
			</Flex>
		</Flex>
	);
}
