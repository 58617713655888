import { useEffect, useRef } from 'react';

import { getWindow } from '@change-corgi/core/window';

import { useIsVenmoQREnabled, usePaypal } from 'src/app/shared/hooks/payments';
import { isLoaded } from 'src/app/shared/utils/async';
import type { PaymentMethodSaveOptions, VenmoClient } from 'src/app/shared/utils/payments';

export type Props = {
	paymentMethodSaveOptions: PaymentMethodSaveOptions;
};

export function useInitializeVenmoInstance({ paymentMethodSaveOptions }: Props): VenmoClient | null {
	const paypalState = usePaypal();

	const venmoRef = useRef<VenmoClient | null>(null);
	const isVenmoQrEnabled = useIsVenmoQREnabled();

	useEffect(() => {
		async function init() {
			if (isLoaded(paypalState) && venmoRef.current === null) {
				venmoRef.current = await getWindow().braintree.venmo.create({
					client: paypalState.braintreeClientInstance,
					paymentMethodUsage: paymentMethodSaveOptions.shouldSavePaymentMethod ? 'multi_use' : 'single_use',
					allowDesktop: isVenmoQrEnabled,
				});
			}
		}
		void init();
		return () => {
			if (venmoRef.current) {
				void venmoRef.current.teardown();
				venmoRef.current = null;
			}
		};
	}, [paypalState, paymentMethodSaveOptions.shouldSavePaymentMethod, isVenmoQrEnabled]);

	return venmoRef.current;
}
