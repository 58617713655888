import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { saveBraintreePaymentMethod } from 'src/app/shared/api/payments';
import type { PaymentMethodSaveOptions, PaymentType } from 'src/app/shared/utils/payments';

export type Props = {
	token: string;
	email: string;
	paymentMethodSaveOptions: PaymentMethodSaveOptions;
	onTokenError: (paymentType: PaymentType, err: Error) => void;
};

export function useSavePaymentMethod(): (props: Props) => Promise<void> {
	const utilityContext = useUtilityContext();

	return async function savePaymentMethod({ email, token, paymentMethodSaveOptions, onTokenError }) {
		if (paymentMethodSaveOptions.shouldSavePaymentMethod) {
			try {
				await saveBraintreePaymentMethod({
					token,
					email,
					usage: paymentMethodSaveOptions.usage,
					utilityContext,
					paymentType: 'VENMO',
				});
			} catch (error) {
				return onTokenError('venmo', error as Error);
			}
		}
		return Promise.resolve();
	};
}
