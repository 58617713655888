import type { PaymentGateway } from '@change-corgi/middle-end-gql-schema';

type Result = {
	gateway: string;
	gatewayLink: string;
	termsOfServiceLink: string;
	privacyPolicyLink: string;
};

export type PolicyInfoProps = {
	gateway: PaymentGateway;
};

const GATEWAY_LINK_MAPPING: Record<PaymentGateway, Record<'href' | 'label', string>> = {
	STRIPE: { href: 'https://stripe.com/legal/ssa', label: 'Stripe' },
	BRAINTREE: { href: 'https://www.paypal.com/us/legalhub/useragreement-full?locale.x=en_US', label: 'Paypal' },
	DLOCAL: { href: 'https://dlocal.com/legal/terms-and-conditions', label: 'dLocal' },
};

export function usePolicyInfo({ gateway }: PolicyInfoProps): Result {
	const { label, href } = GATEWAY_LINK_MAPPING[gateway];
	return {
		gateway: label,
		gatewayLink: href,
		termsOfServiceLink: 'https://www.change.org/policies/terms-of-service#6',
		privacyPolicyLink: 'https://www.change.org/policies/privacy',
	};
}
