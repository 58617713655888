import { type ComponentProps } from 'react';
import React from 'react';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useLocale } from 'src/app/shared/hooks/l10n';
import { usePaypal } from 'src/app/shared/hooks/payments';
import type { WithTokenParams } from 'src/app/shared/types';
import { isLoaded, isLoading } from 'src/app/shared/utils/async';
import type { PaymentMethodSaveOptions, PaymentType } from 'src/app/shared/utils/payments';

import { PaypalButtonLoading } from '../PaypalButtonLoading';
import { PaypalButtonSDK } from '../PaypalButtonSDK';

import { useAvailability } from './hooks/useAvailability';
import { useLoadTimeTracking } from './hooks/useLoadTimeTracking';
import { usePaypalSDK } from './hooks/usePaypalSDK';
import { createBillingAgreement, createOrder, onApprove, onClick, onError } from './utils';

type Props = {
	amount?: number;
	beforeToken: (paymentType: PaymentType) => Promise<boolean>;
	billingAgreementDescription?: string;
	currency: string;
	disabled: boolean;
	email: string;
	flow: paypal.FlowType;
	loading: boolean;
	onTokenError: (paymentType: PaymentType, err: Error) => void;
	onTokenInvalid: (paymentType: PaymentType, err: Error) => void;
	paymentMethodSaveOptions: PaymentMethodSaveOptions;
	style?: ComponentProps<typeof PaypalButtonSDK>['style'];
	validate?: (paymentType: PaymentType) => Promise<boolean>;
	withToken: (params: WithTokenParams) => Promise<void>;
	shouldEnableButton?: boolean;
	trackLoadStartTime: () => void;
	trackLoadEndTime: (duration: number) => void;
	prePaymentChecks?: (paymentType: PaymentType) => Promise<boolean>;
	savePaypalTemporarily?: boolean;
};

// TODO: Use a discriminating union to ensure that we only include the appropriate props for each flow type (vault or checkout)
// Attempted below but ran into type errors with useNewPaypmentMethodPaypal hook

// type BaseProps = {
// 	beforeToken: (paymentType: PaymentType) => void;
// 	currency: string;
// 	disabled: boolean;
// 	email: string;
// 	loading: boolean;
// 	onTokenError: (paymentType: PaymentType, err: Error) => void;
// 	onTokenInvalid: (paymentType: PaymentType, err: Error) => void;
// 	// paymentMethodSaveOptions: PaymentMethodSaveOptions;
// 	validate: (paymentType: PaymentType) => boolean;
// 	withToken: (paymentType: PaymentType, token: string) => void;
// };

// type CheckoutProps = BaseProps & {
// 	flow: paypal.FlowType.Checkout;
// 	amount: number;
// 	paymentMethodSaveOptions: {
// 		shouldSavePaymentMethod: false;
// 		usage: 'OFF_SESSION' | 'ON_SESSION';
// 	};
// };

// type VaultProps = BaseProps & {
// 	flow: paypal.FlowType.Vault;
// 	billingAgreementDescription: string;
// 	paymentMethodSaveOptions: {
// 		shouldSavePaymentMethod: boolean;
// 		usage: 'OFF_SESSION' | 'ON_SESSION';
// 	};
// };

// type Props = CheckoutProps | VaultProps;

export function PaypalButton(props: Props): React.JSX.Element {
	const utilityContext = useUtilityContext();
	const locale = useLocale();
	const paypalState = usePaypal();

	const {
		beforeToken,
		currency,
		disabled,
		email,
		flow,
		loading,
		onTokenError,
		onTokenInvalid,
		paymentMethodSaveOptions,
		validate,
		style,
		withToken,
		shouldEnableButton,
		trackLoadEndTime,
		trackLoadStartTime,
		prePaymentChecks,
		savePaypalTemporarily,
	} = props;
	const trackEndTime = useLoadTimeTracking({ flow, trackLoadStartTime, trackLoadEndTime });
	const initializedSdk = usePaypalSDK(currency, flow);

	const setAvailability = useAvailability(shouldEnableButton);

	const paypalButtonStyle: ComponentProps<typeof PaypalButtonSDK>['style'] = {
		shape: 'rect',
		color: 'white',
		layout: 'horizontal',
		label: 'pay',
		...style,
	};

	if (isLoading(paypalState) || !initializedSdk) return <PaypalButtonLoading style={style} />;

	const paypalCheckout = isLoaded(paypalState) ? paypalState.paypalCheckout : null;

	const paypalButtonProps: ComponentProps<typeof PaypalButtonSDK> = {
		fundingSource: 'paypal',
		style: paypalButtonStyle,
		loading,
		onApprove: async (onApproveData) =>
			onApprove(
				{
					email,
					onApproveData,
					onTokenError,
					paypalCheckout,
					paymentMethodSaveOptions,
					withToken,
					prePaymentChecks,
					savePaypalTemporarily,
				},
				utilityContext,
			),

		onCancel: () => onTokenInvalid('paypal', new Error('User cancelled paypal flow')),
		onError: (error) => onError({ onTokenError, error }),
		onInit: (...onInitProps) => {
			setAvailability(...onInitProps);
			trackEndTime();
		},
		onClick: async () => onClick({ beforeToken, validate }),
	};

	if (flow === 'checkout' && initializedSdk === 'checkout') {
		const { amount } = props;
		paypalButtonProps.createOrder = async () =>
			createOrder({
				amount,
				currency,
				disabled,
				paypalCheckout,
			});
	}

	if (flow === 'vault' && initializedSdk === 'vault') {
		const { billingAgreementDescription } = props;
		paypalButtonProps.createBillingAgreement = async () =>
			createBillingAgreement({
				billingAgreementDescription,
				currency,
				disabled,
				locale,
				paypalCheckout,
			});
	}

	return <PaypalButtonSDK {...paypalButtonProps} />;
}
