import React from 'react';

import { Box } from '@change-corgi/design-system/layout';

import type { WithTokenParams } from 'src/app/shared/types';
import type { PaymentMethodSaveOptions, PaymentType } from 'src/app/shared/utils/payments';

import { PaypalButton } from '../../../../paypal/components/PaypalButton';

import { useNewPaymentMethodPaypal } from './hooks/useNewPaymentMethodPaypal';

export type NewPaymentMethodPaypalProps = {
	currencyCode: string;
	amount: number;
	loading: boolean;
	email: string;
	paymentMethodSaveOptions: PaymentMethodSaveOptions;
	useDonationLanguage: boolean;
	savePaypalTemporarily?: boolean;
	validationResult: (paymentType: PaymentType) => {
		valid: boolean;
		errors: Record<string, string>;
	};
	validate: (paymentType: PaymentType) => Promise<boolean>;
	beforeToken: (paymentType: PaymentType) => Promise<boolean>;
	onTokenError: (paymentType: PaymentType, err: Error) => void;
	onTokenInvalid: (paymentType: PaymentType, err: Error) => void;
	withToken: (params: WithTokenParams) => Promise<void>;
	prePaymentChecks: (paymentType: PaymentType) => Promise<boolean>;
};

export function NewPaymentMethodPaypal(props: NewPaymentMethodPaypalProps): React.JSX.Element {
	const { data, actions } = useNewPaymentMethodPaypal(props);
	const { savePaypalTemporarily } = props;

	return (
		<Box as="div" pb={8}>
			<PaypalButton
				{...data}
				{...actions}
				style={{ color: 'blue' }}
				data-testid="paypal-button"
				savePaypalTemporarily={savePaypalTemporarily}
			/>
		</Box>
	);
}
