import { useEffect, useState } from 'react';

import { useStripe } from '@stripe/react-stripe-js';
import type { CanMakePaymentResult, PaymentRequest } from '@stripe/stripe-js';

type Params = {
	amount: number;
	currencyCode: string;
	label: string;
	requestPayerAddress?: boolean;
};

// eslint-disable-next-line max-lines-per-function
export function usePaymentRequest({ amount, currencyCode, label, requestPayerAddress }: Params): PaymentRequest | null {
	const stripe = useStripe();
	const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);
	const [canMakePayment, setCanMakePayment] = useState<CanMakePaymentResult | null>(null);

	useEffect(() => {
		if (!paymentRequest) return;
		(async () => {
			const cmp = await paymentRequest.canMakePayment();
			setCanMakePayment(cmp);
		})();
	}, [paymentRequest]);

	useEffect(() => {
		if (stripe && paymentRequest && canMakePayment) {
			paymentRequest.update({
				total: {
					amount,
					label,
				},
				...(requestPayerAddress
					? {
							shippingOptions: [
								{
									amount: 0,
									label: '',
									id: 'default',
									detail: '',
								},
							],
						}
					: {}),
			});
		}
	}, [amount, canMakePayment, label, paymentRequest, requestPayerAddress, stripe]);

	if (stripe && !paymentRequest) {
		const pr = stripe.paymentRequest({
			// The Payment Request object must be initialized with our Stripe account's country code
			country: 'US',
			currency: currencyCode.toLowerCase(),
			total: {
				label,
				amount,
			},
			requestPayerName: true,
			requestPayerEmail: true,
			requestShipping: requestPayerAddress,
			shippingOptions: requestPayerAddress
				? [
						{
							amount: 0,
							label: '',
							id: 'default',
							detail: '',
						},
					]
				: undefined,
		});
		setPaymentRequest(pr);
	}

	return canMakePayment && paymentRequest;
}
