import React, { useMemo } from 'react';

import { Box } from '@change-corgi/design-system/layout';

import type { PaymentType } from 'src/app/shared/utils/payments';

import type { Props as VenmoButtonProps } from '../../../../paypal/components/VenmoButton';
import { VenmoButton } from '../../../../paypal/components/VenmoButton';

export type NewPaymentMethodVenmoProps = VenmoButtonProps & {
	validationResult: (paymentType: PaymentType) => {
		valid: boolean;
		errors: Record<string, string>;
	};
};

export function NewPaymentMethodVenmo(props: NewPaymentMethodVenmoProps): React.JSX.Element {
	const { validationResult, ...buttonProps } = props;

	const shouldEnableButton = useMemo(() => {
		return validationResult('venmo').valid;
	}, [validationResult]);

	return (
		<Box as="div" pb={8}>
			<VenmoButton {...buttonProps} shouldEnableButton={shouldEnableButton} data-testid="venmo-button" />
		</Box>
	);
}
