import { useMemo, useState } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { useTracking } from '@change-corgi/core/react/tracking';

import { useRealtimeValidation } from '../../../../container/hooks/useRealtimeValidation';
import { useMembershipModalState } from '../../../../modalContext';

type Result = ModelHookResult<
	{
		displayed: boolean;
		amount: number;
		value: number;
		currency: string;
		isSelected: boolean;
		currencySymbol: string;
		currencySymbolPosition: 'before' | 'after';
		decimalSeparator: string;
		errorMessage: string | null;
		showFractionNumber: boolean;
		isCustomAmount: boolean;
		placeholder: string;
	},
	{
		onClick: () => void;
		onChange: (value: number) => void;
		onFocus: () => void;
	}
>;

export function useAmountSelector(): Result {
	const [{ amount, currencyCode, validationErrors, customAmountEnabled }, { setAmount }] = useMembershipModalState();
	const { getCurrencySymbol, getCurrencySymbolPosition, getDecimalSeparator, isZeroDecimalCurrency, translate } =
		useI18n();

	const [isCustomAmount, setIsCustomAmount] = useState<boolean>(false);
	const [customAmount, setCustomAmount] = useState<number>(0);

	const track = useTracking();

	useRealtimeValidation();

	const originalAmount = useMemo(() => {
		return amount;
		// This is intentional, we only want to run this once to store initial amount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		data: {
			displayed: customAmountEnabled === true,
			amount: originalAmount,
			currency: currencyCode,
			isSelected: !isCustomAmount,
			isCustomAmount,
			errorMessage: customAmount !== 0 ? (validationErrors?.amount ?? null) : null,
			value: isCustomAmount ? amount : 0,
			currencySymbol: getCurrencySymbol(currencyCode),
			currencySymbolPosition: getCurrencySymbolPosition(currencyCode),
			decimalSeparator: getDecimalSeparator(),
			showFractionNumber: !isZeroDecimalCurrency(currencyCode),
			placeholder: translate('corgi.co.promote.custom_amount'),
		},
		actions: {
			onClick: () => {
				setAmount(originalAmount);
				setCustomAmount(0);
				setIsCustomAmount(false);
				void track('post_promote_default_amount_selected');
			},
			onFocus: () => {
				setAmount(customAmount);
				setIsCustomAmount(true);
				void track('post_promote_other_amount_selected');
			},
			onChange: (value) => {
				setAmount(value);
				setCustomAmount(value);
			},
		},
	};
}
