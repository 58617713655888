import camelCase from 'lodash/fp/camelCase';

import { useI18n } from '@change-corgi/core/react/i18n';

import type { SavedPaymentMethodButtonProps } from '../../index';

type Result = ModelHookResult<{
	label: string;
	detailText: string;
	logoSrc: string;
	selected: boolean;
}>;

const LOGO_PAYMENT_METHOD_MAP: Record<string, Record<string, string>> = {
	creditCard: {
		activeFilename: 'https://static.change.org/payment-options/btn/credit-card-new.svg',
		inactiveFilename: 'https://static.change.org/payment-options/btn/cc-inactive-dark.svg',
	},
	americanExpress: {
		activeFilename: 'https://static.change.org/payment-options/btn/amex-active.png',
		inactiveFilename: 'https://static.change.org/payment-options/btn/amex-inactive.png',
	},
	dinersClub: {
		activeFilename: 'https://static.change.org/payment-options/btn/diners-active.png',
		inactiveFilename: 'https://static.change.org/payment-options/btn/diners-inactive.png',
	},
	discover: {
		activeFilename: 'https://static.change.org/payment-options/btn/discover-active.png',
		inactiveFilename: 'https://static.change.org/payment-options/btn/discover-inactive.png',
	},
	jcb: {
		activeFilename: 'https://static.change.org/payment-options/btn/jcb-active.png',
		inactiveFilename: 'https://static.change.org/payment-options/btn/jcb-inactive.png',
	},
	mastercard: {
		activeFilename: 'https://static.change.org/payment-options/btn/mastercard-active.png',
		inactiveFilename: 'https://static.change.org/payment-options/btn/mastercard-inactive.png',
	},
	unionpay: {
		activeFilename: 'https://static.change.org/payment-options/btn/unionpay-active.png',
		inactiveFilename: 'https://static.change.org/payment-options/btn/unionpay-inactive.png',
	},
	visa: {
		activeFilename: 'https://static.change.org/payment-options/btn/visa-active.png',
		inactiveFilename: 'https://static.change.org/payment-options/btn/visa-inactive.png',
	},
	applePay: {
		activeFilename: 'https://static.change.org/payment-options/btn/applepay-active-dark.svg',
		inactiveFilename: 'https://static.change.org/payment-options/btn/applepay-inactive-dark.svg',
	},
	googlePay: {
		activeFilename: 'https://static.change.org/payment-options/btn/googlepay-active.png',
		inactiveFilename: 'https://static.change.org/payment-options/btn/googlepay-inactive.png',
	},
};

// eslint-disable-next-line max-lines-per-function
export function useSavedPaymentMethodButton({
	paymentMethod,
	selectedPaymentType,
	usingSavedPaymentMethod,
}: Omit<SavedPaymentMethodButtonProps, 'onSelectSavedPaymentMethod'>): Result {
	const { translate } = useI18n();

	const selected = usingSavedPaymentMethod && selectedPaymentType === paymentMethod.type;

	let paymentMethodData;

	switch (paymentMethod.__typename) {
		case 'PaymentMethodPayPal':
			paymentMethodData = {
				label: translate('fe.components.payments.types.paypal'), // TODO: Move to corgi namespace
				detailText: paymentMethod.email,
				logoSrc: selected
					? 'https://static.change.org/payment-options/btn/paypal-active-dark.svg'
					: 'https://static.change.org/payment-options/btn/paypal-inactive-dark.svg',
			};
			break;

		case 'PaymentMethodVenmo':
			paymentMethodData = {
				label: translate('corgi.payments.types.venmo'),
				detailText: translate('corgi.payments.venmo.payment_source_info', { username: paymentMethod.username }),
				logoSrc: selected
					? 'https://static.change.org/payment-options/btn/venmo-active.svg'
					: 'https://static.change.org/payment-options/btn/venmo-inactive.svg',
			};
			break;

		case 'PaymentMethodSepa':
			paymentMethodData = {
				label: translate('fe.components.payments.types.sepa'), // TODO: Move to corgi namespace
				detailText: `${'• '.repeat(12)}\u00A0\u00A0${paymentMethod.last4}`,
				logoSrc: selected
					? 'https://static.change.org/payment-options/btn/sepa-active-dark.svg'
					: 'https://static.change.org/payment-options/btn/sepa-inactive-dark.svg',
			};
			break;
		case 'PaymentMethodCreditCard':
			paymentMethodData = {
				label: paymentMethod.brand,
				detailText: `${'• '.repeat(12)}\u00A0\u00A0${paymentMethod.last4}`,
				logoSrc:
					LOGO_PAYMENT_METHOD_MAP[camelCase(paymentMethod.brand.toLowerCase())][
						selected ? 'activeFilename' : 'inactiveFilename'
					],
			};
			break;

		default:
			throw new Error('Unexpected payment method type');
	}

	return {
		data: {
			...paymentMethodData,
			selected,
		},
	};
}
