import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	MembershipExistsByEmail,
	type MembershipExistsByEmailQuery,
	type MembershipExistsByEmailQueryVariables,
} from './api.graphql';

type TestType = {
	email: string | null;
};
export async function membershipExistsByEmail(
	{ email }: TestType,
	{ gql: { fetch } }: UtilityContext,
): Promise<boolean> {
	const { data, errors } = await fetch<MembershipExistsByEmailQuery, MembershipExistsByEmailQueryVariables>({
		query: MembershipExistsByEmail,
		variables: { email: email ?? null },
		rejectOnError: false,
	});

	if (errors) throw new Error(JSON.stringify(errors));
	if (!data) throw new Error('No data returned from query');

	return !!data?.membershipExistsByEmail?.hasMembership;
}
