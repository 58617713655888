import React, { useMemo } from 'react';

import { LocalizeCurrency } from '@change-corgi/core/react/i18n';
import { buttonResetStyles } from '@change-corgi/design-system/reset';
import { Text } from '@change-corgi/design-system/typography';

type Props = {
	amount: number;
	currency: string;
	isSelected: boolean;
	onClick: () => void;
	dataQaAttribute?: string;
};

export function AmountButton({ amount, isSelected, onClick, currency, dataQaAttribute }: Props): React.JSX.Element {
	const colorStyles = useMemo(() => {
		if (isSelected)
			return {
				color: 'typography-primary',
				backgroundColor: 'rgba(21, 125, 185, 0.08)',
				borderColor: 'secondary-blue',
			};
		return {
			color: 'typography-primary',
			backgroundColor: 'primary-white',
			borderColor: 'neutral-grey100',
		};
	}, [isSelected]);

	return (
		<button
			type="button"
			data-amount={amount}
			data-testid="payment-form-amount-button"
			data-qa={dataQaAttribute || 'contribution-amount-button'}
			onClick={onClick}
			aria-pressed={isSelected}
			aria-label={`${amount} ${currency}`}
			sx={{
				...buttonResetStyles,
				borderStyle: 'solid',
				borderWidth: isSelected ? '2px' : '1px',
				borderRadius: 'standard',
				width: '100%',
				height: '48px',
				fontWeight: 'bold',
				...colorStyles,
			}}
		>
			<Text size="small" fontWeight="semibold">
				<LocalizeCurrency value={amount} currency={currency} />
			</Text>
		</button>
	);
}
