import type { JSX } from 'react';

import { Box } from '@change-corgi/design-system/layout';
import { Heading, Text } from '@change-corgi/design-system/typography';

import { useHeader } from './hooks/useHeader';

export function Header(): JSX.Element {
	const { headline, description } = useHeader();

	return (
		<>
			<Box sx={{ mb: 16 }}>
				<Heading size="h3" data-qa="ppmu-modal-header-title">
					{headline}
				</Heading>
			</Box>
			<Text data-qa="ppmu-modal-header-description">{description}</Text>
		</>
	);
}
