import React from 'react';

import { buttonResetStyles } from '@change-corgi/design-system/reset';

import type { PaymentType } from 'src/app/shared/utils/payments';

import { WalletPaymentTypeButton } from './components';
import { usePaymentTypeButton } from './hooks/usePaymentTypeButton';

export function PaymentTypeButton({
	type,
	selectedPaymentType,
	onSelectNewPaymentMethod,
	disabled = false,
	usingSavedPaymentMethod,
	isVariant = false,
	variant,
	availableNewPaymentMethods,
}: {
	type: PaymentType;
	selectedPaymentType: PaymentType;
	onSelectNewPaymentMethod: (selectedPaymentType: PaymentType) => void;
	disabled: boolean;
	usingSavedPaymentMethod?: boolean;
	isVariant?: boolean;
	variant?: string;
	availableNewPaymentMethods?: PaymentType[];
}): React.JSX.Element {
	const {
		data: { label, a11yLabel, logoSrc, width, lineHeight, logoSrcExp, labelExp },
		actions: { setUserSelectedPaymentType },
	} = usePaymentTypeButton({
		type,
		selectedPaymentType,
		onSelectNewPaymentMethod,
	});

	if (isVariant)
		return (
			<WalletPaymentTypeButton
				type={type}
				selectedPaymentType={selectedPaymentType}
				a11yLabel={a11yLabel}
				logoSrc={logoSrcExp ?? logoSrc}
				label={labelExp ?? label}
				setUserSelectedPaymentType={setUserSelectedPaymentType}
				disabled={disabled}
				usingSavedPaymentMethod={usingSavedPaymentMethod}
				variant={variant}
				availableNewPaymentMethods={availableNewPaymentMethods}
				width={width}
			/>
		);

	return (
		<button
			type="button"
			data-testid={`payment-option-button-${type}`}
			aria-label={a11yLabel}
			onClick={setUserSelectedPaymentType}
			disabled={disabled}
			sx={{
				...buttonResetStyles,
				py: 9,
				px: 23,
				height: ['80%', '90%', '100%'],
				width: '100%',
				my: [8, 4, 4],
				lineHeight: '24px',
				borderWidth: selectedPaymentType === type ? 2 : 1,
				borderStyle: 'solid',
				borderRadius: 'standard',
				backgroundColor: selectedPaymentType === type ? 'rgba(21, 125, 185, 0.08)' : 'primary-white',
				borderColor: selectedPaymentType === type ? 'secondary-blue' : 'primary-black',
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'&:focus,&:hover': {
					backgroundColor: 'rgba(21, 125, 185, 0.08)',
					borderColor: 'secondary-blue',
					borderWidth: selectedPaymentType === type ? '2px' : '1px',
				},
				alignItems: 'center',
			}}
		>
			<img alt="" width={width} src={logoSrc} />
			{label && <div sx={{ fontSize: [10, 12], lineHeight, fontWeight: 'normal' }}>{label}</div>}
		</button>
	);
}
