import { useMembershipModalState } from '../../modalContext';

export function useShowPaypalButton(): boolean {
	const [{ gateway, usingSavedPaymentMethod, acceptMembership, tokenizationData, savingPaypalTemporarilyEnabled }] =
		useMembershipModalState();

	return (
		gateway === 'BRAINTREE' &&
		!usingSavedPaymentMethod &&
		tokenizationData?.tokenType !== 'permanent' &&
		!savingPaypalTemporarilyEnabled &&
		acceptMembership
	);
}
