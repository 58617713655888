import type { JSX } from 'react';

import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';

import type { WithTokenParams } from 'src/app/shared/types';
import type { PaymentType } from 'src/app/shared/utils/payments';

import { useOnClick } from './hooks/useOnClick';
import { usePaymentRequest } from './hooks/usePaymentRequest';
import { usePaymentRequestOnCancel } from './hooks/usePaymentRequestOnCancel';
import { usePaymentRequestOnPaymentMethod } from './hooks/usePaymentRequestOnPaymentMethod';

type Props = {
	amount: number;
	beforeToken: (paymentType: PaymentType) => Promise<boolean>;
	currencyCode: string;
	label: string;
	onCancel: () => void;
	paymentType: 'applePay' | 'googlePay';
	validate?: (paymentType: PaymentType) => Promise<boolean>;
	withToken: (params: WithTokenParams) => Promise<void>;
	prePaymentChecks?: (paymentType: PaymentType) => Promise<boolean>;
	requestPayerAddress?: boolean;
};

export function PaymentRequestButton({
	amount, // Should be in base units
	beforeToken,
	currencyCode,
	label,
	onCancel,
	paymentType,
	validate,
	withToken,
	prePaymentChecks,
	requestPayerAddress,
}: Props): JSX.Element | null {
	const paymentRequest = usePaymentRequest({ amount, currencyCode, label, requestPayerAddress });
	const onClick = useOnClick({ beforeToken, paymentRequest, paymentType, validate, prePaymentChecks });
	usePaymentRequestOnCancel(paymentRequest, onCancel);
	usePaymentRequestOnPaymentMethod(paymentRequest, paymentType, withToken);

	if (!paymentRequest) return null;

	return <PaymentRequestButtonElement onClick={onClick} options={{ paymentRequest }} />;
}
