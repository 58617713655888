import { useTrackView } from 'src/app/shared/hooks/tracking';

import { useMembershipModalState } from '../../../modalContext';
import type { ModalCallbackProps } from '../../../shared/types';
import { useSubmit } from '../useSubmit';

import { useCopy } from './hooks/useCopy';

type Result = ModelHookResult<
	{
		acceptMembership: boolean;
		qaSuffix: string;
		copy: ReturnType<typeof useCopy>;
	},
	{
		onSelectYes: () => void;
		onSelectNo: () => void;
		onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
	}
>;

export function useMembershipForm({ onAccept, onDecline }: ModalCallbackProps): Result {
	const submit = useSubmit({ onAccept, onDecline });

	const [
		{ acceptMembership, useDonationLanguage, categoryTags, geoReach, relevantLocationLocalizedName },
		{ setAcceptMembership },
	] = useMembershipModalState();

	useTrackView(
		'post_promote_membership_ask_modal_view',
		{
			category_tags: categoryTags ?? [],
			geo_reach: geoReach ?? undefined,
			relevant_location_localized_name: relevantLocationLocalizedName ?? undefined,
		},
		[],
	);

	const copy = useCopy();

	return {
		data: {
			acceptMembership,
			qaSuffix: useDonationLanguage ? '-donation' : '',
			copy,
		},
		actions: {
			onSelectYes: () => setAcceptMembership(true),
			onSelectNo: () => setAcceptMembership(false),
			onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
				event.preventDefault();
				await submit();
			},
		},
	};
}
