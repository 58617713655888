import type { ChangeEventHandler } from 'react';
import React, { useCallback, useMemo } from 'react';

import type { StripeCardElementChangeEvent } from '@stripe/stripe-js';

import { useI18n } from '@change-corgi/core/react/i18n';
import { Input } from '@change-corgi/design-system/components/forms';
import { Box } from '@change-corgi/design-system/layout';

import { DEFAULT_ERROR_VALIDATION, stripeErrorI18nKey, stripeInputName } from 'src/app/shared/utils/payments/stripe';

import type { StripeCreditCardFormWithInternalProps } from '../../types';

import { CardElementWrapper } from './CardElementWrapper';

export function SingleForm(props: StripeCreditCardFormWithInternalProps): React.JSX.Element {
	const { translate } = useI18n();

	const { onCardDetailsFieldFocus, validationErrors, setValidationErrors } = props;

	const onChange = useCallback(
		(e: StripeCardElementChangeEvent) => {
			setValidationErrors?.({
				...DEFAULT_ERROR_VALIDATION,
				[stripeInputName(e.elementType) as string]: e.error ? stripeErrorI18nKey(e.error) : null,
			});
		},
		[setValidationErrors],
	);

	const error = useMemo(() => {
		if (validationErrors?.card) {
			return translate(validationErrors.card);
		}
		if (validationErrors?.card_number) {
			return translate(validationErrors.card_number);
		}
		if (validationErrors?.card_expiration) {
			return translate(validationErrors.card_expiration);
		}
		if (validationErrors?.card_cvc) {
			return translate(validationErrors.card_cvc);
		}
		return null;
	}, [validationErrors, translate]);

	return (
		<Box
			data-testid="credit-card-number"
			sx={{
				position: 'relative',
				WebkitTransform: 'translate3d(0, 0, 0)',
			}}
		>
			<Input
				className="iframe-form-element" // TODO: this is here for the regression-qaa tests. Migrate the tests to use the data-qa attribute.
				data-qa="iframe-form-element"
				error={error}
				helperText={translate('corgi.co.promote.card_details')}
				name="card-details"
				as={CardElementWrapper}
				onFocus={onCardDetailsFieldFocus}
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				onChange={onChange as unknown as ChangeEventHandler<HTMLInputElement>}
				id="card-details-input"
				sx={{
					height: 40,
					display: 'flex',
					alignItems: 'center',
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'.StripeElement': {
						width: '100%',
					},
				}}
			/>
		</Box>
	);
}
