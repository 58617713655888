import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	type PaymentsSharedSetupPaymentDLocalFieldsFragment,
	PaymentsSharedSetupPaymentMethod,
	type PaymentsSharedSetupPaymentMethodMutation,
	type PaymentsSharedSetupPaymentMethodMutationVariables,
	type PaymentsSharedSetupPaymentMethodSuccessFieldsFragment,
	type PaymentsSharedSetupPaymentStripeFieldsFragment,
} from './setupPaymentMethod.graphql';

// TODO utilityContext should be a separate parameter
type Params = PaymentsSharedSetupPaymentMethodMutationVariables & { utilityContext: UtilityContext };

export type SetupPaymentMethodSuccess = PaymentsSharedSetupPaymentMethodSuccessFieldsFragment;
export type DLocalCard = PaymentsSharedSetupPaymentDLocalFieldsFragment['card'];
export type StripeSetupIntent = PaymentsSharedSetupPaymentStripeFieldsFragment['setupIntent'];

export type StripeSetupFailure = Extract<
	PaymentsSharedSetupPaymentMethodMutation['setupPaymentMethod'],
	{ __typename: 'SetupPaymentMethodError' }
>;

export async function setupPaymentMethod({
	input: { gateway, token, usage, type, customer, dlocal, stripe },
	utilityContext,
}: Params): Promise<SetupPaymentMethodSuccess> {
	const {
		gql: { fetch },
	} = utilityContext;

	try {
		const { data, errors } = await fetch<
			PaymentsSharedSetupPaymentMethodMutation,
			PaymentsSharedSetupPaymentMethodMutationVariables
		>({
			query: PaymentsSharedSetupPaymentMethod,
			variables: { input: { gateway, token, usage, type, customer, dlocal, stripe } },
			path: '/payments/setupPaymentMethod',
			rejectOnError: false,
			batched: false, // to ensure this rate limited mutation is not batched
		});
		if (errors) throw new Error(JSON.stringify(errors));
		if (!data) throw new Error('No data returned from mutation.');
		// FIXME throw error with message
		// eslint-disable-next-line @typescript-eslint/only-throw-error
		if (data.setupPaymentMethod.__typename === 'SetupPaymentMethodError') throw data.setupPaymentMethod;
		return data.setupPaymentMethod;
	} catch (e) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
		(e as any).operationName = 'setup_payment_method';
		throw e;
	}
}
